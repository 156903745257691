import axios from "axios";

// const base_URL = "http://localhost:3007";
const base_URL = "https://dev-app-backend.herokuapp.com";

//CRUD functions
// I have seperated the calls and functions for clarity so post call with Create function and so on

const postAPICall = async (url, product) => {
  const response = await axios.post(url, product);
  return response;
};

//Create function: the function to create new products
export const postProducts = async (product) => {
  const productsUrl = base_URL + "/new";
  try {
    const response = await postAPICall(productsUrl, product);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

// (Get Call)
const getAPICall = async (url) => {
  const response = await axios.get(url);
  return response;
};

//Read function: the function to get products from the server
export const getProducts = async () => {
  const productsUrl = base_URL + "/products/";
  try {
    const response = await getAPICall(productsUrl);
    return response.data;
  } catch (error) {
    return error.message;
  }
};

// (Put call)
const putAPICall = async (url, product) => {
  const response = await axios.put(url, {
    _id: product._id,
    name: product.name,
    manufacturer: product.manufacturer,
    description: product.description,
    image: product.image,
  });
  return response;
};

// Update function: the function to update the products
export const editProducts = async (product) => {
  const productsUrl = base_URL + `update/${product._id}`;
  try {
    const response = await putAPICall(productsUrl, product);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

// (Delete call)
const deleteAPICall = async (url, id) => {
  const response = await axios.delete(url, id);
  return response;
};

//Delete function: the function to delete a product
export const deleteProducts = async (product) => {
  const productsUrl = base_URL + `/delete/${product._id}`;
  const deletedID = `${product._id}`;
  try {
    await deleteAPICall(productsUrl, `${product._id}`).then(
      `${alert("Product deleted")}`
    );
    return deletedID;
  } catch (error) {
    return error.response;
  }
};

//for search function
export const getProductsSearch = async (query) => {
  const productsUrl = base_URL + "/find/:name";
  try {
    const response = await axios.post(productsUrl, query);
    return response.data;
  } catch (error) {
    return error.response;
  }
};
