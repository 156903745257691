import React from "react";
//function to show the laoding spinner it just adds the div with the class and the actual magic happens with CSS spin class
function Loading() {
  return (
    <div className="spinner-container">
      <div className="spin"></div>
    </div>
  );
}

export default Loading;
