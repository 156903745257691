import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { deleteProducts } from "../api/productsRequest";

//Deleting products using the ID and the method imported form productRequests
export default function DelProducts({ products, setProducts }) {
  let navigate = useNavigate();
  const { productId } = useParams();
  let product = false;

  if (productId) {
    product = products.filter((obj) => {
      return obj._id === productId;
    });
  }

  const handleForm = (e) => {
    e.preventDefault();

    deleteProducts(product[0]).then((res) => {
      console.log(res);
      setProducts((currentProducts) =>
        currentProducts.filter((item) => item._id !== res)
      );
      navigate("/");
    });
  };
  return (
    <>
      {product[0] && (
        <div>
          <form
            id="create-form"
            className="form-item update-form-container"
            onSubmit={handleForm}
          >
            <h1>Delete Product:</h1>
            <img
              src={product[0].image}
              alt={product[0].name}
              style={{ width: "300px", height: "200px", borderRadius: "10px" }}
            />

            <label htmlFor="Name">Name</label>
            <input
              name="name"
              type="text"
              placeholder="product Name"
              className="form-item"
              onChange={(e) => {
                product[0].name = e.target.value;
              }}
              defaultValue={`${product[0].name}`}
            />
            <label htmlFor="Description">Description</label>
            <input
              name="description"
              type="text"
              placeholder="Description"
              className="form-item"
              onChange={(e) => {
                product[0].description = e.target.value;
              }}
              value={`${product[0].description}`}
            />
            <div className="form-buttons ">
              <button type="submit" className="form-item form-btn delete">
                Delete
              </button>
              <span style={{ minWidth: "20px" }}></span>
              <div
                className="form-item form-btn"
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                Cancel
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
