import React, { useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";

//pages
import CreateProduct from "./pages/CreateProduct.js";
import Home from "./pages/Home.js";
import UpdateProduct from "./pages/UpdateProduct.js";
import DeleteProduct from "./pages/DeleteProduct.js";
import Info from "./pages/Info.js";
import NavBar from "./components/NavBar.js";

function App() {
  //Global state storage
  const [products, setProducts] = useState();
  const [search, setSearch] = useState(false);
  //returning the app
  return (
    <div className="App">
      <header className="App-header"></header>
      <NavBar products={products} search={search} />
      <Routes>
        <Route
          path="/"
          exact={true}
          element={
            <Home
              products={products}
              setProducts={setProducts}
              search={search}
              setSearch={setSearch}
            />
          }
        />

        <Route
          path="/create-product"
          element={
            <CreateProduct products={products} setProducts={setProducts} />
          }
        />

        <Route
          path="/delete-product/:productId"
          element={
            <DeleteProduct products={products} setProducts={setProducts} />
          }
        />

        <Route
          path="/update-product/:productId"
          element={
            <UpdateProduct products={products} setProducts={setProducts} />
          }
        />
        <Route path="/info" exact={true} element={<Info />} />
      </Routes>
    </div>
  );
}
//finished, exporting app
export default App;
