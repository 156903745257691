//imports
import React from "react";

// page to create a new product
export default function info() {
  return (
    <div className="container">
      <div className="info-inner">
        <h2>Some info about the APP</h2>
        <h3>General description of how application works </h3>
        <p>
          The app is divided into the front end and the back end, the front end
          uses React JS and axios to poll the server and display the data
        </p>
        <p>
          And the Backend is made with Express and node.js. It fields the
          incoming requests by exposing API endpoints where the client can send
          GET, POST, PUT and DELETE requests (The CRUD operations). The data is
          returned in JSON format and the frontend cleint can then display this
          data to the users
        </p>

        <h4>Frontend</h4>

        <p>
          This frontend app is based on React and node.js I have broken it down
          in different folders for a better structure so the individual
          components are in the components folder {">"} the CSS is in the css
          folder and pages are seperated in the pages folder. When you start the
          app you land in the Home page section and the initial read is carried
          out immediately, if there is any latency with the call, a spinner is
          shows that indicates that the page is loading.
        </p>
        <h3>Functionality: (CRUD)</h3>
        <p>
          Once loaded, the page displays a set number of products that can be
          paginated to the next or previous page. By clicking the forward or
          back arrow buttons on the bottom of the page(initial get request)
        </p>
        <p>
          There is an option to create more products by clicking the "Create"
          button on the nav bar and adding the product information on the
          resulting form and Submitting the form notifies of the product
          creation then it redirects to home page where new product is shown as
          the first product on the page (The Post request)
        </p>
        <p>
          <strong>
            On each product card there is the option to edit and delete the
            product by clicking the buttons at the bottom of the product card.
          </strong>
          <br />
          <strong>Edit: </strong>Clicking on the Edit button on the product card
          (the pencil icon), you will land at the product edit page with the
          form pre-filled with the product information, you can then edit that
          information and the page will inform you of the update and redirect
          you back to the home page with the product updated.
          <strong>Delete: </strong>Clicking on the Delete button on the product
          card (the X icon), you will land at the product delete page with the
          product name and image + discription from here you can delete (this
          will delete the product, produce an alert and take you back to the
          home page once you click ok on the alert) or click cancel (this will
          take you back to the home page)
        </p>

        <p>
          Search: You can search the product by name here by entering the name
          in the search bar on the navigation bar on the top.
        </p>

        <h4>Backend</h4>

        <p>
          The backend of this app is based on express and node.js I am using a
          mongoose to connect to the database. I have created the database on
          MongoDB atlas cluster and added the connection string to the .env
          folder as the MONGO_URI this ensures that the connection is made with
          the database I have created and you do not need to create and connect
          the local instance of mongo.
          <br />
          In the controller I am importing the schema created in the model {">"}
          model.js folder to connect to the database. I then use that schema to
          create CRUD functions
        </p>
        <h3>Functionality: (CRUD)</h3>
        <p>
          {" "}
          The Router (routes {">"} prod_router) specifies 5 routes: <br /> The
          one for the home "/" get route just renders a basic ejs page using the
          ejs engine that specifies the routes. <br />
          The post route "/new" is the route for creating new product endpoint.{" "}
          <br />
          The get "/products" route is for getting all the products (capped at
          250). <br />
          The put "/update/:id" route specifies an api endpoint for updating a
          product using the ID
          <br />
          And finally the delete route at "/delete/:id" route deletes the
          product by the given ID{" "}
        </p>
        <p>
          <strong>The Controller</strong> <br />
          The controller file in server {">"} controller {">"} controller
          specifies the functions used on the router. <br />
          The Create function for the /new route. The create function itself
          takes the request body and cast it into the schema model and then
          using async function saves the product to the database. <br />
          The find function returns all the products and limits them to 250 this
          number can be increased if you are looking to test performance (line
          30) and sorts them by newest first.
          <br />
          The update function takes in the id that is sent via the axios request
          from the frontend and updates product with the sent data
          <br />
          The delete function takes in the id that is sent via the axios request
          from the frontend and deletes the product
        </p>
        <p>
          <strong>Model</strong>
          The mongoose schema is defined in the model and helps us make requests
          using the schema later.
          <br />
          <strong>Server.js</strong>
          This is where all the magic happens we bring all the information and
          requiremenst that the app needs to function and later on bind it
          together into a coherent whole in server and serve the API routes
          defined in routes.
        </p>
        <h3>Description of the technologies involved in your project. </h3>
        <p>
          {" "}
          As mentioned above, the backend and frontend both used node and the
          backend used Express and mongoDB and the frontend used react. I also
          used morgan to log info and helmet to log request in the server (So
          you can see what is going on ) And ofcourse mongoose to make requests
          to mongo db. On the frontend I used axios to poll the API endpoints
          and displayed the data using react.
        </p>
        <h3>Description of some weaknesses of your application</h3>

        <p>
          I am not super familiar with React so the app is a bit clunky and I
          would have loved some more time to actually implement the security
          feature properly.
        </p>
        <h3>
          Description of some alternatives you could use to implement your
          application
        </h3>
        <p>
          I was thinking of using redux to make the pains by react better but it
          is totally unfamiliar framework for me and I did not want to bog down
          in the learning process with so little time to actually implement all
          that was needed.
        </p>
      </div>
    </div>
  );
}
