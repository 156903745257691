import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { editProducts } from "../api/productsRequest";

export default function UpdateProduct({ products }) {
  let navigate = useNavigate();
  const { productId } = useParams();
  let product = false;

  if (productId) {
    product = products.filter((obj) => {
      return obj._id === productId;
    });
  }

  const handleForm = (e) => {
    e.preventDefault();

    editProducts(product[0]).then((res) => {
      alert("Product edited!");
      navigate("/");
    });
  };
  return (
    <div>
      <form
        id="create-form"
        className="form-item update-form-container"
        onSubmit={handleForm}
      >
        <h1>Edit product</h1>
        <label htmlFor="Name">Name</label>
        <input
          name="name"
          type="text"
          placeholder="product Name"
          className="form-item"
          onChange={(e) => {
            product[0].name = e.target.value;
          }}
          defaultValue={`${product[0].name}`}
        />

        <label htmlFor="price">Price</label>
        <input
          name="price"
          type="number"
          placeholder="0.00"
          className="form-item"
          onChange={(e) => {
            product[0].price = e.target.value;
          }}
          defaultValue={`${product[0].price}`}
        />

        <label htmlFor="shipping">Shipping</label>
        <input
          name="shipping"
          type="number"
          placeholder="0.00"
          className="form-item"
          onChange={(e) => {
            product[0].shipping = e.target.value;
          }}
          defaultValue={`${product[0].shipping}`}
        />

        <label htmlFor="Manufecturer">Manufecturer</label>
        <input
          name="manufacturer"
          type="text"
          placeholder="Manufecturer"
          className="form-item"
          onChange={(e) => {
            product[0].manufacturer = e.target.value;
          }}
          defaultValue={`${product[0].manufacturer}`}
        />

        <label htmlFor="Description">Description</label>
        <input
          name="description"
          type="text"
          placeholder="Description"
          className="form-item"
          onChange={(e) => {
            product[0].description = e.target.value;
          }}
          defaultValue={`${product[0].description}`}
        />

        <label htmlFor="image">Image URL</label>
        <input
          name="image"
          type="text"
          placeholder="Image URL"
          className="form-item"
          onChange={(e) => {
            product[0].image = e.target.value;
          }}
          defaultValue={`${product[0].image}`}
        />
        <div className="form-buttons">
          <button
            onSubmit={(e) => {
              handleForm();
            }}
            className="form-item form-btn"
          >
            Submit
          </button>
          <span style={{ minWidth: "20px" }}></span>
          <div
            className="form-item form-btn"
            onClick={(e) => {
              window.location.href = "/";
            }}
          >
            Cancel
          </div>
        </div>
      </form>
    </div>
  );
}
