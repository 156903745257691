import React, { useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

//defining the number of products that would show up on a single page by using react states
function ProductCards({ products, search }) {
  const [currentPage, setCurrentPage] = useState(0);
  const pageLength = 12;
  const [page, setPage] = useState(
    products.slice(currentPage, currentPage + pageLength)
  );
  console.log(search);
  return (
    <div>
      {!search && (
        <>
          {page.map((product) => {
            return (
              //returning the grid container with each product mapped onto the below template
              <div className="grid-container" key={uuidv4()}>
                {/* making a background div for image container */}
                <div
                  style={{
                    width: "300px",
                    height: "300px",
                    //adding a background image under the product image so the broken image shows if any of the actual images are broken
                    backgroundImage: `url("https://www.drupal.org/files/project-images/broken-image.jpg")`,
                    borderRadius: "10px",
                    backgroundPosition: "centre",
                    backgroundSize: "cover",
                  }}
                >
                  {/* making a background div for image and setting the background as the image form the DB  */}
                  <section className="grid-item grid-item-4">
                    <a target="_blank" rel="noreferrer" href={product.url}>
                      <div
                        style={{
                          width: "300px",
                          height: "300px",
                          backgroundImage: `url("${product.image}" )`,
                          borderRadius: "10px",
                          backgroundPosition: "centre",
                          backgroundSize: "cover",
                        }}
                      />
                    </a>
                  </section>
                </div>
                {/* Populating the rest of the values  */}
                <div className="grid-info">
                  <section className="grid-item grid-item-1">
                    Name: <br />
                    {product.name}
                  </section>
                  <section className="grid-item grid-item-2">
                    Manufecturer: <br />
                    {product.manufacturer}
                  </section>
                  <div
                    style={{
                      display: "inline-flex",
                    }}
                  >
                    <section className="grid-item grid-item-3">
                      Price: <br /> `${product.price["$numberDecimal"]}`
                    </section>
                    <section className="grid-item grid-item-3">
                      Shipping: <br /> `${product.shipping}`
                    </section>
                  </div>

                  <section className="grid-item grid-item-5">
                    Description: <br />
                    {product.description}
                  </section>
                </div>

                <div>
                  <a
                    className="purchase-button"
                    target="_blank"
                    rel="noreferrer"
                    href={product.url}
                  >
                    Purchase
                  </a>
                </div>
                {/* control buttons for editing or deleting the product  */}
                <div className="control-container">
                  <button className="control-button edit">
                    <Link to={`/update-product/${product._id}`}>✎</Link>
                  </button>

                  <div className="control-button delete">
                    <Link to={`/delete-product/${product._id}`}>✘</Link>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
      {search && (
        <>
          <div className="search-back">
            <a className="navbar-button" href="/">
              <strong>⇦ Back Home</strong>
            </a>
          </div>
          {search.map((product) => {
            return (
              //returning the grid container with each product mapped onto the below template
              <div className="grid-container" key={uuidv4()}>
                {/* making a background div for image container */}
                <div
                  style={{
                    width: "300px",
                    height: "300px",
                    //adding a background image under the product image so the broken image shows if any of the actual images are broken
                    backgroundImage: `url("https://www.drupal.org/files/project-images/broken-image.jpg")`,
                    borderRadius: "10px",
                    backgroundPosition: "centre",
                    backgroundSize: "cover",
                  }}
                >
                  {/* making a background div for image and setting the background as the image form the DB  */}
                  <section className="grid-item grid-item-4">
                    <a target="_blank" rel="noreferrer" href={product.url}>
                      <div
                        style={{
                          width: "300px",
                          height: "300px",
                          backgroundImage: `url("${product.image}" )`,
                          borderRadius: "10px",
                          backgroundPosition: "centre",
                          backgroundSize: "cover",
                        }}
                      />
                    </a>
                  </section>
                </div>
                {/* Populating the rest of the values  */}
                <div className="grid-info">
                  <section className="grid-item grid-item-1">
                    Name: <br />
                    {product.name}
                  </section>
                  <section className="grid-item grid-item-2">
                    Manufecturer: <br />
                    {product.manufacturer}
                  </section>
                  <div
                    style={{
                      display: "inline-flex",
                    }}
                  >
                    <section className="grid-item grid-item-3">
                      Price: <br /> `${product.price["$numberDecimal"]}`
                    </section>
                    <section className="grid-item grid-item-3">
                      Shipping: <br /> `${product.shipping}`
                    </section>
                  </div>

                  <section className="grid-item grid-item-5">
                    Description: <br />
                    {product.description}
                  </section>
                </div>

                <div>
                  <button
                    className="purchase-button"
                    target="blank"
                    href={product.url}
                  >
                    Purchase
                  </button>
                </div>
                {/* control buttons for editing or deleting the product  */}
                <div className="control-container">
                  <button className="control-button edit">
                    <Link to={`/update-product/${product._id}`}>✎</Link>
                  </button>

                  <div className="control-button delete">
                    <Link to={`/delete-product/${product._id}`}>✘</Link>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
      {/* setting up pagination, (back and forward buttons) */}
      <div className="pagination">
        <div
          className="pagination-buttons"
          onClick={() => {
            // to ensure that the button only goes back if its on page 1 or more (because we start at page 0)
            if (currentPage > 0) {
              setCurrentPage(currentPage - pageLength);
              setPage(products.slice(currentPage, currentPage + pageLength));
            }
          }}
        >
          ⇦
        </div>
        <div
          className="pagination-buttons"
          onClick={() => {
            setCurrentPage(currentPage + pageLength);
            setPage(products.slice(currentPage, currentPage + pageLength));
          }}
        >
          ⇨
        </div>
      </div>
    </div>
  );
}

export default ProductCards;
