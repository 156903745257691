import React, { useState, useEffect } from "react";
import { getProducts } from "../api/productsRequest";
//componets
import ProductCards from "../components/products/ProductCards.js";
import Loading from "../components/Loading.js";
import NavBar from "../components/NavBar.js";

function Home({ products, setProducts, search, setSearch }) {
  //set the loading message when getting data
  const [loading, setloading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      //set the loading to true while the request is being sent and an answer received

      setloading(true);
      const prods = await getProducts().then((res) => {
        return res;
      });
      setProducts(prods);

      //set the loading to true while the request is being sent and an answer received

      setloading(false);
    };
    fetchData();
  }, [setProducts]);

  return (
    <div>
      <NavBar
        products={products}
        setProducts={setProducts}
        search={search}
        setSearch={setSearch}
      />
      <div className="container">
        {loading && <Loading />}
        {products && <ProductCards products={products} search={search} />}
      </div>
    </div>
  );
}

export default Home;
