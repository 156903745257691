//imports
import React from "react";
import { useNavigate } from "react-router-dom";
import { postProducts } from "../api/productsRequest";

// page to create a new product
export default function CreateProduct() {
  //using navigate to redirect after the product has been created
  let navigate = useNavigate();
  //function to handle form so the default action can be prevented and to use the post products method to create a product
  //the base URL where we make requests to if you are having issues please check the server is running on th ecorrect port
  const base_url = "http://localhost:3007";

  //adding the page redirect to the base URL so we can make request to it
  const request_url = base_url + "/new/";
  const handleForm = (e) => {
    e.preventDefault();
    //handling form submission and navigation back to the home page
    postProducts().then(() => {
      alert("Product created!");
      navigate("/");
    });
  };
  return (
    <div>
      {/* returning the form that will  */}
      <form
        action={request_url}
        method="post"
        id="create-form"
        className="form-item update-form-container"
      >
        <label htmlFor="Name">Name</label>
        <input
          name="name"
          type="text"
          placeholder="product Name"
          className="form-item"
        />

        <label htmlFor="price">Price</label>
        <input
          name="price"
          type="text"
          placeholder="0.00"
          className="form-item"
        />

        <label htmlFor="shipping">Shipping</label>
        <input
          name="shipping"
          type="text"
          placeholder="0.00"
          className="form-item"
        />

        <label htmlFor="Manufecturer">Manufecturer</label>
        <input
          name="manufacturer"
          type="text"
          placeholder="Manufecturer"
          className="form-item"
        />

        <label htmlFor="Description">Description</label>
        <input
          name="description"
          type="text"
          placeholder="Description"
          className="form-item"
        />

        <label htmlFor="image">Image URL</label>
        <input
          name="image"
          type="text"
          placeholder="Image URL"
          className="form-item"
          defaultValue="https://picsum.photos/300"
        />

        <div className="form-buttons">
          <button
            onSubmit={(e) => {
              handleForm();
            }}
            className="form-item form-btn"
          >
            Submit
          </button>
          <span style={{ minWidth: "20px" }}></span>
          {/* cancel button redirects back to the home page  */}
          <div
            className="form-item form-btn"
            onClick={(e) => {
              window.location.href = "/";
            }}
          >
            Cancel
          </div>
        </div>
      </form>
    </div>
  );
}
