import React from "react";
import { Link } from "react-router-dom";
// import AuthNav from "./auth-nav";
// import { getProductsSearch } from "../api/productsRequest";
function NavBar({ products, setProducts, search, setSearch }) {
  function SearchHandler(e) {
    console.log(products);
    e.preventDefault();
    console.log(e.target.value);
    let result = products.filter((product) => {
      return product.name.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setSearch(result);
    console.log(result);
  }
  return (
    <nav className="navbar">
      {/* Only adding Home (read) and Create buttons as the update and delete happens via the buttons on the product */}
      <Link to="/">
        <div id="fetchAll" className="navbar-button">
          Home
        </div>
      </Link>
      <Link to="/create-product">
        <div className="navbar-button">Create</div>
      </Link>

      <Link to="/info">
        <div className="navbar-button"> About </div>
      </Link>
      {/* <AuthNav /> */}
      {/* search function that I might not have time to implement  */}
      <div className="navbar-auth">
        <label htmlFor="name">Search:</label>
        <input
          onKeyDown={(e) => {
            return e.key === "Enter" ? SearchHandler(e) : "";
          }}
          type="text"
          name="name"
        />
      </div>
    </nav>
  );
}

export default NavBar;

//<form action="http://localhost:3007/find" method="POST">
